import oss from 'ali-oss';
import moment from 'moment';
import {message} from "ant-design-vue"

const client = (bucket) => {
  return new oss({
    accessKeyId: 'LTAIbW3TXatTSBZH',
    accessKeySecret: 'nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW',
    region: 'oss-cn-qingdao', //
    bucket: bucket,//
    secure: true,
  });
}

const uploadPath = (file) => {
  // 上传文件的路径，使用日期命名文件目录
  return `${moment().format('YYYYMMDD')}/${file.uid}-${file.name}`
}
const UploadToOss = (file, bucket, obj) => {
  const url = uploadPath(file)
  return new Promise((resolve, reject) => {
    client(bucket).multipartUpload(url, file, obj).then(data => {
      resolve(data);
    }).catch(error => {
      reject(error)
    })
  })
}

const upload = (file, bucket, obj) => {
  return new Promise((resolve) => {
    let isJpgOrPng = true, isLt2M = true
    if(bucket == "softmall-images") {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === "image/jpg" || file.type === "image/gif";
      if (!isJpgOrPng) {
        message.error('请上传PNG、JPG、GIF图片格式！');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('上传图片必须小于 2MB!');
      }
    }
    if(isJpgOrPng && isLt2M) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        UploadToOss(file, bucket, obj).then(data => {
          if(data.res.status == 200) {
            resolve(data.res.requestUrls[0].split('?')[0])
          } else {
            message.error('图片上传失败!');
          }
        })
      }
    } else {
      return isJpgOrPng && isLt2M;
    }
  })
}


export default upload